import React from 'react'
import _ from 'lodash'
import {Flex} from "reflexbox";

const calendlyEvents = {
  profile_page_viewed: 'calendly.profile_page_viewed',
  event_type_viewed: 'calendly.event_type_viewed',
  date_and_time_selected: 'calendly.date_and_time_selected',
  event_scheduled: 'calendly.event_scheduled',
};

export default class Calendly extends React.Component {
    calendlyScriptSrc = 'https://assets.calendly.com/assets/external/widget.js';
    calendlyURL = process.env.REACT_APP_FRONT_CALENDLY_URL;

    constructor(props) {
        super(props);

        this.buildCalendlyURL = this.buildCalendlyURL.bind(this);
        this.handleCalendlyEvents = this.handleCalendlyEvents.bind(this);
    }

    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');

        script.setAttribute('src', this.calendlyScriptSrc);

        window.addEventListener('message', this.handleCalendlyEvents);

        head.appendChild(script);
    }

    componentWillUnmount() {
        const head = document.querySelector('head');
        const script = document.querySelector('script');

        window.removeEventListener('message', this.handleCalendlyEvents);

        head.removeChild(script);
    }

    isCalendlyEvent(e) {
        return e.data.event && e.data.event.indexOf('calendly') === 0;
    }

    handleCalendlyEvents(e) {
        if (!this.isCalendlyEvent(e)) {
            return;
        }

        if (e.data.event === calendlyEvents.event_scheduled) {
            this.props.onEventScheduled(e);
        } else if (e.data.event === calendlyEvents.event_type_viewed) {
            this.setState({loading: false});
        }
    };


    buildCalendlyURL() {
        let url = this.calendlyURL + '?embed_type=Inline&text_color=29303c&primary_color=1b42f7';
        const {fullName, email} = this.props;

        url += '&embed_domain=' + encodeURIComponent(_.get(
            location, // eslint-disable-line no-restricted-globals
            'origin', ''
        ));

        if (fullName) {
            url += '&name=' + encodeURIComponent(fullName);
        }

        if (email) {
            url += '&email=' + encodeURIComponent(email);
        }

        return url;
    }

    render() {
        return (
            <Flex flexGrow="1" justifyContent="center" alignItems="center" flexDirection="column">
                <Flex height="15%" justifyContent="center" alignItems="center" flexDirection="column">
                    <Flex fontSize="1.5rem">
                        Discutons ensemble de vos besoins !
                    </Flex>
                    <Flex fontSize="2rem" style={{position: 'relative', zIndex: '2'}}>
                        Programmez un rendez-vous téléphonique avec notre équipe !
                        <div style={{
                            backgroundColor: '#005CFA',
                            width: '100%',
                            height: '10px',
                            position: 'absolute',
                            zIndex: '-1',
                            bottom: '8px'
                        }}/>
                    </Flex>
                </Flex>
                <Flex
                    flexGrow="1"
                    justifyContent="center"
                    alignItems="center"
                    className="calendly-inline-widget"
                    data-url={this.buildCalendlyURL()}
                    style={{minWidth: '100%', height: '100%'}}
                />
            </Flex>
        )
    }
}
