import React from 'react'
import {Flex} from "reflexbox";
import SearchBar from '../../components/searchbar';
import {withRouter} from "react-router-dom";

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.onSearchSubmit = this.onSearchSubmit.bind(this);
    }

    onSearchSubmit(value) {
        if (value !== '') {
            this.props.history.push('/search?q=' + value)
        }
    }

    render() {
        return (
            <Flex flexDirection="column" height="100%">
                <Flex height="30%" justifyContent="center" alignItems="center" flexDirection="column">
                    <Flex fontSize="1.5rem">
                        Accédez gratuitement aux documents légaux de
                    </Flex>
                    <Flex fontSize="3rem" style={{position: 'relative', zIndex: '2'}}>
                        10 millions d'entreprises françaises
                        <div style={{
                            backgroundColor: '#005CFA',
                            width: '100%',
                            height: '20px',
                            position: 'absolute',
                            zIndex: '-1',
                            bottom: '8px'
                        }}/>
                    </Flex>
                </Flex>
                <Flex justifyContent="center" flexGrow="1" style={{paddingTop: '10%'}}>
                    <SearchBar onSearchSubmit={this.onSearchSubmit}/>
                </Flex>
            </Flex>
        )
    }
}

export default withRouter(Index)
