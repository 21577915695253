import {Flex} from "reflexbox";
import React from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-light-svg-icons";

const SearchButton = styled(({onClick, ...props}) => (
    <Flex justifyContent="center" alignItems="center" onClick={onClick} {...props}>
        <FontAwesomeIcon icon={faSearch}/>
    </Flex>
))`
    min-width: 60px;
    width: 60px;
    height: 60px;
    background: linear-gradient(225deg, #F9CC6B, #FBB07D);
    border-radius: 100%;
    position: absolute;
    color: white;
    right: -10px;
    top: -14%;
    
    &:hover {
        cursor: pointer;
    }
`;

const SearchInput = styled(({onChange, onSubmit, value, ...props}) => (
    <input type="text" placeholder="Siren ou nom de l'entreprise" value={value} onChange={onChange} {...props}/>
))`
    border: none;
    width: calc(100% - 40px);
    
    &:focus {
        outline: none;
    }
`;

class SearchBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({value: e.target.value})
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.onSearchSubmit(this.state.value);
    }

    render() {
        const {value} = this.state;
        const {onSearchSubmit, ...props} = this.props;

        return (
            <Flex {...props}>
                <Flex flexGrow="1" style={{position: 'relative'}}>
                    <Flex flexGrow="1">
                        <form onSubmit={this.onSubmit} style={{width: '100%'}}>
                            <SearchInput onChange={this.onChange} value={value}/>
                        </form>
                    </Flex>
                </Flex>
                <SearchButton onClick={this.onSubmit}/>
            </Flex>
        )
    }
}

export default styled(SearchBar)`
    background-color: white;
    padding: 11px;
    border-radius: 21px;
    min-width: 29%;
    width: 29%;
    box-shadow: 7px 18px 39px 2px rgba(0,0,0,0.2);
    height: fit-content;
    position: relative;
`
