import {client} from './client';

export const apiSettings = {
    rootUrl: process.env.REACT_APP_FRONT_API_URL,
};

export const fetchCompanies = (denomination, siren) => {
    if (denomination !== null) {
        return client.get(`${apiSettings.rootUrl}/companies/?denomination=${denomination}`);
    }

    return client.get(`${apiSettings.rootUrl}/companies/?siren=${siren}`);
};

export const fetchActs = siren =>
    client.get(`${apiSettings.rootUrl}/companies/${siren}/acts`);

export const fetchAct = (siren, file_id) =>
    client.get(`${apiSettings.rootUrl}/companies/${siren}/acts/${file_id}`);

export const postProspect = (firstName, lastName, company, email) =>
    client.post(`${apiSettings.rootUrl}/prospects/`, {firstName, lastName, company, email});
