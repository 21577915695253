const _fetch = (url, options) => fetch(url, {
    headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }),
    ...options,
}).then(function (response) {
    if (response.status === 204) {
        return Promise.resolve();
    }

    if (response.ok) {
        return response.json();
    }

    return response.json().then(json => Promise.reject(json))
});

const clientFetch = (url, options) => {
    url = new URL(url);
    return _fetch(url, options);
};

export const client = {
    get(url, data = {}) {
        let urlClass = new URL(url);

        Object.keys(data)
            .filter(key => data[key] !== undefined)
            .forEach(key => {
                if (Array.isArray(data[key])) {
                    data[key].forEach(value => {
                        urlClass.searchParams.append(key + '[]', value);
                    });
                } else {
                    urlClass.searchParams.append(key, data[key]);
                }
            });

        return clientFetch(urlClass.href, {
            method: 'GET',
        });
    },

    post(url, data = {}) {
        return clientFetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        });
    },
};
