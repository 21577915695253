import {Flex} from "reflexbox/src";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

export default styled(({theme, icon, title, childre, ...otherProps}) => (
    <Flex
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        flexDirection="column"
        my={4}
        {...otherProps}
    >
        <Flex my={3}>
            <FontAwesomeIcon icon={icon} size="4x"/>
        </Flex>
        {title}
    </Flex>
))`
    color: #ddd;
`;
