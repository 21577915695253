import React from 'react';
import styled from "styled-components";
import {Flex} from "reflexbox";

export default styled(({children, ...otherProps}) => (
    <Flex minHeight="100%" {...otherProps}>
        {children}
    </Flex>
))`
    background: 'white';
`;
