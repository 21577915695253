export default {
    "401": "MANOSQUE",
    "6303": "CLERMONT - FERRAND",
    "5401": "BRIEY",
    "5501": "BAR - LE - DUC",
    "7702": "MELUN",
    "1407": "LISIEUX",
    "7701": "MEAUX",
    "1402": "CAEN",
    "1801": "BOURGES",
    "7102": "CHALON - SUR - SAONE",
    "301": "CUSSET",
    "4201": "ROANNE",
    "8305": "TOULON",
    "203": "SOISSONS",
    "7001": "VESOUL",
    "3802": "VIENNE",
    "5902": "DUNKERQUE",
    "5910": "LILLE METROPOLE",
    "1601": "ANGOULEME",
    "2801": "CHARTRES",
    "2903": "QUIMPER",
    "1304": "SALON - DE - PROVENCE",
    "7501": "PARIS",
    "6901": "LYON",
    "7301": "CHAMBERY",
    "3701": "TOURS",
    "5101": "CHALONS - EN - CHAMPAGNE",
    "2104": "DIJON",
    "4001": "DAX",
    "3402": "BEZIERS",
    "1104": "NARBONNE",
    "7402": "THONON - LES - BAINS",
    "4402": "SAINT - NAZAIRE",
    "4002": "MONT - DE - MARSAN",
    "4302": "LE PUY-EN - VELAY",
    "7601": "DIEPPE",
    "2301": "GUERET",
    "5103": "REIMS",
    "3102": "TOULOUSE",
    "5301": "LAVAL",
    "3801": "GRENOBLE",
    "3405": "MONTPELLIER",
    "1301": "AIX - EN - PROVENCE",
    "8102": "CASTRES",
    "1303": "MARSEILLE",
    "5906": "VALENCIENNES",
    "6502": "TARBES",
    "5601": "LORIENT",
    "8801": "EPINAL",
    "6202": "BOULOGNE - SUR - MER",
    "8002": "AMIENS",
    "8901": "AUXERRE",
    "7401": "ANNECY",
    "7801": "EVRY",
    "5952": "DOUAI",
    "802": "SEDAN",
    "5201": "CHAUMONT",
    "5002": "COUTANCES",
    "2401": "BERGERAC",
    "702": "AUBENAS",
    "3502": "SAINT MALO",
    "6403": "PAU",
    "3303": "LIBOURNE",
    "7106": "MACON",
    "4901": "ANGERS",
    "6101": "ALENCON",
    "9001": "BELFORT",
    "9301": "BOBIGNY",
    "8401": "AVIGNON",
    "6001": "BEAUVAIS",
    "4101": "BLOIS",
    "2901": "BREST",
    "3302": "BORDEAUX",
    "4401": "NANTES",
    "8501": "LA ROCHE-SUR - YON",
    "4801": "MENDE",
    "7802": "PONTOISE",
    "8602": "POITIERS",
    "2402": "PERIGUEUX",
    "8302": "DRAGUIGNAN",
    "6903": "VILLEFRANCHE - TARARE",
    "7202": "LE MANS",
    "101": "BOURG - EN - BRESSE",
    "5602": "VANNES",
    "4601": "CAHORS",
    "1101": "CARCASSONNE",
    "2001": "AJACCIO",
    "3601": "CHATEAUROUX",
    "5001": "CHERBOURG",
    "7901": "NIORT",
    "3501": "RENNES",
    "1305": "TARASCON",
    "2602": "ROMANS",
    "2501": "BESANCON",
    "5402": "NANCY",
    "2702": "EVREUX",
    "4701": "AGEN",
    "601": "ANTIBES",
    "2701": "BERNAY",
    "603": "GRASSE",
    "7606": "LE HAVRE",
    "8201": "MONTAUBAN",
    "6601": "PERPIGNAN",
    "202": "SAINT - QUENTIN",
    "8701": "LIMOGES",
    "4502": "ORLEANS",
    "3902": "LONS - LE - SAUNIER",
    "1901": "BRIVE",
    "1704": "LA ROCHELLE",
    "8903": "SENS",
    "2202": "SAINT - BRIEUC",
    "9401": "CRETEIL",
    "602": "CANNES",
    "8101": "ALBI",
    "6002": "COMPIEGNE",
    "6201": "ARRAS",
    "901": "FOIX",
    "5802": "NEVERS",
    "8303": "FREJUS",
    "2002": "BASTIA",
    "4202": "SAINT - ETIENNE",
    "7803": "VERSAILLES",
    "303": "MONTLUCON",
    "1501": "AURILLAC",
    "9201": "NANTERRE",
    "605": "NICE",
    "501": "GAP",
    "1203": "RODEZ",
    "1708": "SAINTES",
    "1001": "TROYES",
    "7608": "ROUEN",
    "3003": "NIMES",
    "6401": "BAYONNE",
    "3201": "AUCH",
}
