import _ from 'lodash'

const computeLightVersion = (colorName) => {
    return theme.colors.lights[_.invert(theme.colors)[colorName]];
};

const theme = {
    colors: {
        primary: '#111f39',
        secondary: '#3EB5E6',
        blueGrey: '#324152',
        blueGreyLight: '#293b54',
        anotherBlue: '#283a53',
        carolina: '#7285A5',
        fadeBlue: '#283a53',
        blueBerry: '#1b2b46',
        yellow: '#FBB07D',
        pink: '#EF709F',
        green: '#a5c34c',
        purple: '#744EAA',
        blue: '#4286F4',
        red: '#eb4d4b',
        redFaded: '#ff000438',
        orange: '#fcc100',
        lights: {
            green: '#cfdfa0',
            pink: '#f7bad1',
            yellow: '#fdd2b5',
            purple: '#d6cbe7',
            blue: '#b7d1fb',
            red: '#f7bbba',
            orange: '#ffedb3',
        },
        lightVersion: computeLightVersion,
    },
    fonts: {
        colors: {
            primary: '#080234',
            faded: '#fff',
        },
    },
    gradients: {
        blue: 'linear-gradient(225deg, #61D9D7, #25A6FA)',
        pink: 'linear-gradient(225deg, #EF709F, #EC8B6E)',
        yellow: 'linear-gradient(225deg, #F9CC6B, #FBB07D)',
        purple: 'linear-gradient(225deg, #6A74DE, #744EAA)',
        green: 'linear-gradient(225deg, #56AB2F, #A8E063)',
    },
};

export default theme;
