import React from 'react'
import {Flex} from "reflexbox";
import {withRouter} from "react-router-dom";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import {PROFILE_ADMIN} from "../../reference/profiles";

class God extends React.Component {
    componentDidMount() {
        this.makeMeGod()
    }

    makeMeGod() {
        const ls = new LocalStorageHelper();

        ls.setProfile(PROFILE_ADMIN);
    }

    render() {
        return (
            <Flex flexDirection="column" height="100%">
                <Flex height="30%" justifyContent="center" alignItems="center" flexDirection="column">
                    <Flex fontSize="2rem" style={{position: 'relative', zIndex: '2'}}>
                        Welcome to the club !
                        <div style={{
                            backgroundColor: '#005CFA',
                            width: '100%',
                            height: '10px',
                            position: 'absolute',
                            zIndex: '-1',
                            bottom: '8px'
                        }}/>
                    </Flex>
                </Flex>
                <Flex flexGrow="1" justifyContent="center">
                    <iframe src="https://giphy.com/embed/3o84sq21TxDH6PyYms" width="480" height="208" frameBorder="0"
    className="giphy-embed" title="god-gif"/>
                </Flex>
            </Flex>
        )
    }
}

export default withRouter(God)
