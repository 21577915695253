import React from 'react'
import {Flex} from "reflexbox";
import {withRouter} from "react-router-dom";
import {postProspect} from "../../api";
import queryString from 'query-string';
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import Button from "react-bootstrap/Button";
import _ from 'lodash'

class Register extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            emailError: false,
            firstName: '',
            firstNameError: false,
            lastName: '',
            lastNameError: false,
            company: '',
        };

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFirstNameChange = this.onFirstNameChange.bind(this);
        this.onLastNameChange = this.onLastNameChange.bind(this);
        this.onCompanyChange = this.onCompanyChange.bind(this);
    }

    onEmailChange(e) {
        this.setState({
            email: e.target.value,
        });
    }

    onFirstNameChange(e) {
        this.setState({
            firstName: e.target.value,
        });
    }

    onLastNameChange(e) {
        this.setState({
            lastName: e.target.value,
        });
    }

    onCompanyChange(e) {
        this.setState({
            company: e.target.value,
        });
    }

    onSubmit() {
        const {email, firstName, lastName, company} = this.state;
        let errorState = {};

        if (firstName.trim() === '') {
            errorState.firstNameError = true;
        }

        if (lastName.trim() === '') {
            errorState.lastNameError = true;
        }

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) {
            errorState.emailError = true
        }

        if (_.isEmpty(errorState)) {
            const ls = new LocalStorageHelper();

            postProspect(firstName, lastName, company, email).finally(() => {
                ls.setPersonnals(firstName, lastName, email, company).then(() => {
                    ls.register().then(() => {
                        const values = queryString.parse(this.props.location.search);

                        this.props.history.push(atob(values.backTo))
                    });
                });
            });
        } else {
            this.setState(errorState);
        }
    }

    render() {
        const {email, emailError, firstName, firstNameError, lastName, lastNameError, company} = this.state;

        return (
            <Flex flexDirection="column" height="100%">
                <Flex height="30%" justifyContent="center" alignItems="center" flexDirection="column">
                    <Flex fontSize="1.5rem">
                        Il semble que notre produit vous intéresse....
                    </Flex>
                    <Flex fontSize="2rem" style={{position: 'relative', zIndex: '2'}}>
                        dîtes nous en un peu plus sur vous !
                        <div style={{
                            backgroundColor: '#005CFA',
                            width: '100%',
                            height: '10px',
                            position: 'absolute',
                            zIndex: '-1',
                            bottom: '8px'
                        }}/>
                    </Flex>
                </Flex>
                <Flex flexGrow="1" justifyContent="center">
                    <Flex flexDirection="column" flexGrow="1" alignItems="center">
                        <Flex style={{
                            color: '#ddd',
                            fontSize: '1rem',
                            marginBottom: '10px',
                            justifyContent: 'left',
                            width: '30%'
                        }}>Prénom</Flex>
                        <input type="text" placeholder="Prénom" value={firstName} onChange={this.onFirstNameChange}
                               style={{
                                   marginBottom: firstNameError ? '10px' : '20px',
                                   width: '30%',
                                   padding: '10px',
                                   borderRadius: '10px',
                                   border: '1px solid ' + (firstNameError ? '#ff7877' : '#ddd'),
                               }}/>
                        {
                            firstNameError && <Flex style={{marginBottom: '10px', color: '#ff7877'}}>
                                Oops... il semble que votre prénom soit invalide !
                            </Flex>
                        }
                        <Flex style={{
                            color: '#ddd',
                            fontSize: '1rem',
                            marginBottom: '10px',
                            justifyContent: 'left',
                            width: '30%'
                        }}>Nom</Flex>
                        <input type="text" placeholder="Nom" value={lastName} onChange={this.onLastNameChange}
                               style={{
                                   marginBottom: lastNameError ? '10px' : '20px',
                                   width: '30%',
                                   padding: '10px',
                                   borderRadius: '10px',
                                   border: '1px solid ' + (lastNameError ? '#ff7877' : '#ddd'),
                               }}/>
                        {
                            lastNameError && <Flex style={{marginBottom: '10px', color: '#ff7877'}}>
                                Oops... il semble que votre nom soit invalide !
                            </Flex>
                        }
                        <Flex style={{
                            color: '#ddd',
                            fontSize: '1rem',
                            marginBottom: '10px',
                            justifyContent: 'left',
                            width: '30%'
                        }}>Société</Flex>
                        <input type="text" placeholder="Société" value={company} onChange={this.onCompanyChange}
                               style={{
                                   marginBottom: '20px',
                                   width: '30%',
                                   padding: '10px',
                                   borderRadius: '10px',
                                   border: '1px solid #ddd',
                               }}/>
                        <Flex style={{
                            color: '#ddd',
                            fontSize: '1rem',
                            marginBottom: '10px',
                            justifyContent: 'left',
                            width: '30%'
                        }}>Adresse email</Flex>
                        <input type="text" placeholder="Adresse email" value={email} onChange={this.onEmailChange}
                               style={{
                                   marginBottom: emailError ? '20px' : '60px',
                                   width: '30%',
                                   padding: '10px',
                                   borderRadius: '10px',
                                   border: '1px solid ' + (emailError ? '#ff7877' : '#ddd'),
                               }}/>
                        {
                            emailError && <Flex style={{marginBottom: '40px', color: '#ff7877'}}>
                                Oops... il semble que cette adresse email soit invalide !
                            </Flex>
                        }
                        <Button style={{backgroundColor: '#005CFA'}} onClick={this.onSubmit}>Continuer votre
                            recherche</Button>
                    </Flex>
                </Flex>
            </Flex>
        )
    }
}

export default withRouter(Register)
