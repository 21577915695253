import React from 'react'
import {Flex} from "reflexbox";
import {withRouter} from "react-router-dom";
import SearchBar from '../../components/searchbar';
import Loader from '../../components/loader';
import Error from '../../components/error';
import {fetchActs, apiSettings} from "../../api";
import styled from "styled-components";
import DateTime from "luxon/src/datetime";
import PDFViewer from 'pdf-viewer-reactjs';
import COURT_CODES from '../../reference/courtCodes';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/pro-light-svg-icons";
import {PROFILE_ADMIN} from "../../reference/profiles";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import queryString from "query-string";

const CompanySummary = styled(({denomination, count, ...props}) => (
    <Flex flexDirection="column" p={2} py={4} {...props}>
        <Flex className="query">
            Documents légaux de&nbsp;
            <Flex style={{position: 'relative', zIndex: 2}}>
                {denomination}
                <div style={{
                    backgroundColor: '#005CFA',
                    width: '100%',
                    height: '20px',
                    position: 'absolute',
                    zIndex: '-1',
                    bottom: '8px'
                }}/>
            </Flex>
        </Flex>
        <Flex className="count">
            {count} document{(count > 1 && 's') || ''}
        </Flex>
    </Flex>
))`
    padding-top: 1%;
    padding-bottom: 1%;
    
    >.query {
        font-size: 3rem;   
    }
    
    >.count {
        font-size: 1.4rem;
        color: #888888;
    }
`;

const ID = styled(({fileId, ...props}) => (
    <Flex justifyContent="center" pl={4} flexDirection="column" {...props}>
        <Flex style={{color: '#ddd', fontSize: '.8rem'}}>N° de document</Flex>
        <Flex>
            {fileId}
        </Flex>
    </Flex>
))`
    width: 25%;
`;

const Court = styled(({courtCode, ...props}) => (
    <Flex flexGrow="1" justifyContent="center" alignItems="center" flexDirection="column" {...props}>
        <Flex style={{color: '#ddd', fontSize: '.8rem'}}>Tribunal</Flex>
        <Flex>
            {
                COURT_CODES[courtCode] !== undefined ? COURT_CODES[courtCode] : courtCode
            }
        </Flex>
    </Flex>
))`
    width: 25%;
`;

const DepositDate = styled(({depositDate, ...props}) => (
    <Flex flexGrow="1" justifyContent="center" alignItems="center" flexDirection="column" {...props}>
        <Flex style={{color: '#ddd', fontSize: '.8rem'}}>Date de dépôt</Flex>
        <Flex>
            {DateTime.fromFormat(depositDate, 'yMMdd').toFormat('dd/MM/y')}
        </Flex>
    </Flex>
))`
    width: 25%;
`;

const DownloadButton = styled(({onClick, ...props}) => (
    <Flex justifyContent="center" alignItems="center" onClick={onClick} {...props}>
        <FontAwesomeIcon icon={faDownload}/>
    </Flex>
))`
    min-width: 40px;
    width: 40px;
    height: 40px;
    background: linear-gradient(225deg, #F9CC6B, #FBB07D);
    border-radius: 100%;
    color: white;
    
    &:hover {
        cursor: pointer;
    }
`;

const Download = styled(({onClick, props}) => (
    <Flex flexGrow="1" justifyContent="center" alignItems="center" flexDirection="column" {...props}>
        <DownloadButton onClick={(e) => {
            e.preventDefault();
            onClick()
        }}/>
    </Flex>
))`
    width: 25%;
`;

const ActItem = styled(({onClickDownload, fileId, courtCode, depositDate, ...props}) => (
    <Flex mb={3} {...props}>
        <ID fileId={fileId}/>
        <Court courtCode={courtCode}/>
        <DepositDate depositDate={depositDate}/>
        <Download onClick={onClickDownload}/>
    </Flex>
))`
    background-color: white;
    width: 95%;
    height: 100px;
    border-radius: 15px;
    border: 2px solid ${props => props.active ? 'rgb(0, 92, 250)' : '#eee'};
    
    &:hover {
        box-shadow: 7px 18px 39px 2px rgba(0,0,0,0.1);
        cursor: pointer;
    }
`;

const ActsList = styled(({acts, onChangeAct, onClickDownload, currentActId, ...props}) => (
    <Flex flexDirection="column" pt={3} alignItems="center" {...props}>
        {
            acts.map((act, idx) => (
                <ActItem key={act.id} fileId={act.id} courtCode={act.court_code} depositDate={act.deposit_date}
                         onClick={() => onChangeAct(idx)} active={act.id === currentActId}
                         onClickDownload={() => onClickDownload(act.id)}/>
            ))
        }
    </Flex>
))`
    min-width: 40% !important;
    width: 40%;
`;

class Companies extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            error: false,
            siren: props.match.params.siren,
            denomination: null,
            acts: [],
            selectedAct: 0,
        };

        this.changeAct = this.changeAct.bind(this);
        this.getActs = this.getActs.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onClickDownload = this.onClickDownload.bind(this);
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);

        if (values.download !== undefined) {
            this.onClickDownload(values.download);
        }

        this.getActs(this.props.match.params.siren);
    }

    onClickDownload(fileId) {
        const {siren} = this.state;
        const ls = new LocalStorageHelper();

        ls.getProfile().then(profile => {
            if (profile !== PROFILE_ADMIN) {
                ls.isRegistered().then(registered => {
                    if (!registered) {
                        this.props.history.push('/register?backTo=' + btoa(this.props.location.pathname + '?download=' + fileId))
                    } else {
                        window.open(`${apiSettings.rootUrl}/companies/${siren}/acts/${fileId}`);
                    }
                });
            } else {
                window.open(`${apiSettings.rootUrl}/companies/${siren}/acts/${fileId}`);
            }
        });
    }

    onSearchSubmit(value) {
        if (value !== '') {
            this.props.history.push('/search?q=' + value);
        }
    }

    getActs(siren) {
        fetchActs(siren).then(result => {
            this.setState({
                denomination: result.denomination,
                acts: result.acts,
                ready: true,
            });
        }).catch(error => {
            this.setState({
                error: true,
                ready: true,
            })
        })
    }

    changeAct(idx) {
        this.setState({selectedAct: idx})
    }

    render() {
        const {ready, denomination, acts, selectedAct, error} = this.state;
        const siren = this.props.match.params.siren;

        return (
            <Flex flexDirection="column" height="100%">
                <Flex justifyContent="center" pt={4}>
                    <SearchBar onSearchSubmit={this.onSearchSubmit}/>
                </Flex>
                {
                    (
                        !ready && <Loader
                            title="Récupération des documents de l'entreprise. Cela peut prendre plusieurs minutes."/>
                    ) || (
                        error && <Error message="Une erreur s'est produite lors de la récupération des documents"/>
                    ) || (
                        <Flex flexDirection="column" flexGrow="1">
                            <CompanySummary denomination={denomination} count={acts.length}/>
                            <Flex flexGrow="1" style={{backgroundColor: '#F6F7F9'}}>
                                <ActsList onChangeAct={this.changeAct} acts={acts} currentActId={acts[selectedAct].id}
                                          onClickDownload={this.onClickDownload}/>
                                <PDFViewer
                                    document={{
                                        url: `${apiSettings.rootUrl}/companies/${siren}/acts/${acts[selectedAct].id}`
                                    }}
                                    navbarOnTop={true}
                                    protectContent={true}
                                    page={1}
                                    key={acts[selectedAct].id}
                                />
                            </Flex>
                        </Flex>
                    )
                }
            </Flex>
        )
    }
}

export default withRouter(Companies)
