import React from 'react'
import {withRouter} from "react-router-dom";
import Calendly from '../../components/calendly';
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import Loader from "../../components/loader";
import {Flex} from "reflexbox";
import Error from '../../components/error';

class Register extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: null,
            lastName: null,
            email: null,
            company: null,
            ready: false,
            error: false,
            appointmentScheduled: false,
        };

        this.getFullName = this.getFullName.bind(this);
        this.onEventScheduled = this.onEventScheduled.bind(this);
    }

    onEventScheduled() {
        const ls = new LocalStorageHelper();

        ls.setAppointmentScheduled().then(() => {
            this.setState({
                appointmentScheduled: true,
            });
        });
    }

    getFullName() {
        const {firstName, lastName} = this.state;

        if (!firstName || !lastName) {
            return null;
        }

        return `${firstName} ${lastName}`;
    }

    componentDidMount() {
        const ls = new LocalStorageHelper();

        ls.isAppointmentScheduled().then(scheduled => {
            if (scheduled) {
                this.setState({ready: true, appointmentScheduled: true});

                return;
            }

            ls.getPersonnals().then(personnals => {
                this.setState({...personnals, ready: true});
            }).catch(() => {
                this.setState({ready: true, error: true});
            });
        }).catch(() => {
            this.setState({ready: true, error: true});
        })
    }

    render() {
        const {email, ready, error, appointmentScheduled} = this.state;

        if (!ready) {
            return (
                <Flex>
                    <Loader title="Chargement du calendrier"/>
                </Flex>
            )
        }

        if (appointmentScheduled) {
            return (
                <Flex flexGrow="1" justifyContent="center" alignItems="center" flexDirection="column">
                    <Flex fontSize="2rem" style={{position: 'relative', zIndex: '2'}}>
                        Votre rendez-vous avec notre équipe est bien enregistré !
                        <div style={{
                            backgroundColor: '#005CFA',
                            width: '100%',
                            height: '10px',
                            position: 'absolute',
                            zIndex: '-1',
                            bottom: '8px'
                        }}/>
                    </Flex>
                    <Flex fontSize="1.5rem">
                        L'accès à la plateforme est restreint jusqu'à votre échange avec l'équipe d'AIFA
                    </Flex>
                </Flex>
            )
        }

        if (error) {
            return (<Flex>
                <Error message="Une erreur s'est produite"/>
            </Flex>)
        }

        return (
            <Calendly fullName={this.getFullName()} email={email} onEventScheduled={this.onEventScheduled}/>
        )
    }
}

export default withRouter(Register)
