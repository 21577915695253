import React from 'react'
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import {PROFILE_ADMIN} from "../reference/profiles";

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withProtection(WrappedComponent) {
    class WithProtection extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                ready: false,
            }
        }

        componentDidMount() {
            const {location: {pathname}} = this.props;

            const ls = new LocalStorageHelper();

            ls.getRequestsCount().then(count => {
                const requestsCount = pathname === '/search' ? count + 1 : count;

                ls.getProfile().then(profile => {
                    if (profile === PROFILE_ADMIN) {
                        this.setState({
                            ready: true,
                        });

                        return;
                    }

                    if (requestsCount >= 8) {
                        ls.setRequestsCount(8).then(() => {
                            this.props.history.push('/appointment')
                        });
                    } else if (requestsCount >= 2) {
                        ls.isRegistered().then(registered => {
                            if (!registered) {
                                ls.setRequestsCount(2).then(() => {
                                    this.props.history.push('/register?backTo=' + btoa(this.props.location.pathname + this.props.location.search))
                                });

                                return;
                            }

                            ls.setRequestsCount(requestsCount).then(() => {
                                this.setState({ready: true})
                            });
                        })
                    } else {
                        ls.setRequestsCount(requestsCount).then(() => {
                            this.setState({ready: true})
                        });
                    }
                });
            });
        }

        render() {
            const {ready} = this.state;

            return ready && <WrappedComponent {...this.props}/>
        }
    }

    WithProtection.displayName = `Protected(${getDisplayName(WrappedComponent)})`;

    return WithProtection
}
