import React from 'react'
import {ThemeProvider} from 'styled-components'
import theme from '../theme'
import {createGlobalStyle} from "styled-components";
import {BrowserRouter as Router} from "react-router-dom";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Blinker'), local('Blinker-Regular'), url(https://fonts.gstatic.com/s/blinker/v1/cIf9MaFatEE-VTaP9CChYUsEkIpdQQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Blinker'), local('Blinker-Regular'), url(https://fonts.gstatic.com/s/blinker/v1/cIf9MaFatEE-VTaP9C6hYUsEkIo.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html,
body,
#root {
    font-family: Blinker, sans-serif;
    height: 100%;
    margin: 0;
    color: ${props => props.theme.fonts.colors.primary};
}

a {
    color: ${props => props.theme.fonts.colors.primary};
}
`;

export default ({children}) => (
    <ThemeProvider theme={theme}>
        <React.Fragment>
            <GlobalStyle/>
            <Router>
                {children}
            </Router>
        </React.Fragment>
    </ThemeProvider>
);
