import {PROFILE_USER} from '../reference/profiles'

export default class LocalStorageHelper {
    getProfile = () => {
        return new Promise((resolve, reject) => {
            try {
                const profile = localStorage.getItem('profile');

                if (profile === null) {
                    return resolve(PROFILE_USER);
                }

                return resolve(profile);
            } catch (e) {
                return reject(e.toString());
            }
        });
    };

    setProfile = profile => {
        return new Promise((resolve, reject) => {
            try {
                localStorage.setItem('profile', profile);
                return resolve(profile)
            } catch (e) {
                return reject(e.toString())
            }
        })
    };

    getRequestsCount = () => {
        return new Promise((resolve, reject) => {
            try {
                const requestsCount = localStorage.getItem('requestsCount');

                if (requestsCount === null) {
                    return resolve(0);
                }

                return resolve(parseInt(requestsCount));
            } catch (e) {
                return reject(e.toString());
            }
        });
    };

    setRequestsCount = count => {
        return new Promise((resolve, reject) => {
            try {
                localStorage.setItem('requestsCount', count);
                return resolve(count)
            } catch (e) {
                return reject(e.toString())
            }
        });
    };

    setPersonnals = (firstName, lastName, email, company) => {
        return new Promise((resolve, reject) => {
            try {
                localStorage.setItem('personnals', JSON.stringify({
                    firstName, lastName, email, company
                }));
                return resolve()
            } catch (e) {
                return reject(e.toString())
            }
        });
    };

    getPersonnals = () => {
        return new Promise((resolve, reject) => {
            try {
                const personnals = localStorage.getItem('personnals');

                if (personnals === null) {
                    return resolve(0);
                }

                return resolve(JSON.parse(personnals));
            } catch (e) {
                return reject(e.toString());
            }
        });
    };

    register = () => {
        return new Promise((resolve, reject) => {
            try {
                localStorage.setItem('registered', 'yes');
                return resolve(true)
            } catch (e) {
                return reject(e.toString())
            }
        });
    };

    isRegistered = () => {
        return new Promise((resolve, reject) => {
            try {
                const registered = localStorage.getItem('registered');

                if (registered === null) {
                    return resolve(false);
                }

                return resolve(registered === 'yes');
            } catch (e) {
                return reject(e.toString());
            }
        });
    };

    clear = () => {
        return new Promise((resolve, reject) => {
            try {
                localStorage.clear();
                return resolve();
            } catch (e) {
                return reject(e.toString());
            }
        });
    };

    isAppointmentScheduled() {
        return new Promise((resolve, reject) => {
            try {
                const appointmentScheduled = localStorage.getItem('appointmentScheduled');

                if (appointmentScheduled === null) {
                    return resolve(false);
                }

                return resolve(appointmentScheduled === 'yes');
            } catch (e) {
                return reject(e.toString());
            }
        });
    }

    setAppointmentScheduled() {
        return new Promise((resolve, reject) => {
            try {
                localStorage.setItem('appointmentScheduled', 'yes');
                return resolve(true)
            } catch (e) {
                return reject(e.toString())
            }
        });
    };
}
