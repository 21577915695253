import React from 'react'
import {Flex} from "reflexbox";
import {withRouter} from "react-router-dom";
import SearchBar from '../../components/searchbar';
import SearchResults from '../../components/searchResults';
import Loader from '../../components/loader';
import styled from 'styled-components'
import {fetchCompanies} from "../../api";
import queryString from 'query-string';
import Error from '../../components/error';

const SearchResultsSummary = styled(({query, count, ...props}) => (
    <Flex flexDirection="column" p={2} py={4} {...props}>
        <Flex className="query">
            Résultats pour "{query}"
        </Flex>
        <Flex className="count">
            {count} résultat{(count > 1 && 's') || ''}
        </Flex>
    </Flex>
))`
    padding-top: 1%;
    padding-bottom: 1%;
    
    >.query {
        font-size: 3rem;   
    }
    
    >.count {
        font-size: 1.4rem;
        color: #888888;
    }
`;

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            searchResults: [],
            searchResultsCount: 0,
            query: null,
            error: false,
        };

        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.getCompanies = this.getCompanies.bind(this);
    }

    onSearchSubmit(value) {
        if (value !== '') {
            this.props.history.push('/search?q=' + value);
        }
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        this.getCompanies(values.q);
    }

    getCompanies(query) {
        this.setState({
            ready: false,
            searchResults: [],
            searchResultsCount: 0,
            query: null,
        });

        let params = [null, null];

        this.setState({
            query
        });

        if (RegExp('[0-9]{3} ?[0-9]{3} ?[0-9]{3}').test(query)) {
            params[1] = query.replace(/- +?/g, '');
        } else {
            params[0] = query;
        }

        fetchCompanies(...params).then(results => {
            this.setState({
                searchResults: results.members,
                searchResultsCount: results.count,
                ready: true,
            });
        }).catch(() => {
            this.setState({
                ready: true,
                error: true,
            })
        });
    }

    render() {
        const {ready, query, searchResults, searchResultsCount, error} = this.state;

        return (
            <Flex flexDirection="column" height="100%">
                <Flex justifyContent="center" pt={4}>
                    <SearchBar onSearchSubmit={this.onSearchSubmit}/>
                </Flex>
                {
                    (
                        !ready && <Loader title="Recherche en cours : cela peut prendre jusqu'à une minute."/>
                    ) || (
                        error && <Error message="Une erreur s'est produite lors de la recherche"/>
                    ) || (
                        <React.Fragment>
                            <SearchResultsSummary query={query} count={searchResultsCount}/>
                            <SearchResults results={searchResults}/>
                        </React.Fragment>
                    )
                }
            </Flex>
        )
    }
}

export default withRouter(Search)
