import styled from "styled-components";
import {Flex} from "reflexbox";
import React from "react";
import Placeholder from "../placeholder";
import {faSadTear} from "@fortawesome/pro-light-svg-icons";
import {withRouter} from "react-router-dom";

const Denomination = styled(({denomination, ...props}) => (
    <Flex justifyContent="center" flexDirection="column" pl={4} {...props}>
        <Flex style={{color: '#ddd', fontSize: '.8rem'}}>Nom de la société</Flex>
        {denomination}
    </Flex>
))`
    width: 33%;
    font-size: 1.2rem;
`;

const Siren = styled(({siren, ...props}) => (
    <Flex justifyContent="center" flexDirection="column" {...props}>
        <Flex style={{color: '#ddd', fontSize: '.8rem'}}>Numéro SIREN</Flex>
        <Flex>
            {siren.slice(0, 3)} {siren.slice(3, 6)} {siren.slice(6, 9)}
        </Flex>
    </Flex>
))`
    width: 33%;
`;

const Count = styled(({count, ...props}) => (
    <Flex flexGrow="1" justifyContent="center" alignItems="center" flexDirection="column" {...props}>
        <Flex style={{
            color: '#ddd',
            fontSize: '.8rem'
        }}>Document{(count > 1 && 's') || ''} disponible{(count > 1 && 's') || ''}</Flex>
        <Flex style={{fontSize: '1.5rem'}}>{count}</Flex>
    </Flex>
))`
    width: 34%;
`;

class SR extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.props.history.push(`/companies/${this.props.siren}`)
    }

    render() {
        const {denomination, siren, count, ...props} = this.props;

        return (
            <Flex mb={3} onClick={this.onClick} {...props}>
                <Denomination denomination={denomination}/>
                <Siren siren={siren}/>
                <Count count={count}/>
            </Flex>
        )
    }
}

const SearchResult = withRouter(styled(SR)`
    background-color: white;
    width: 80%;
    height: 100px;
    border-radius: 15px;
    border: 1px solid #eee;
    
    &:hover {
        box-shadow: 7px 18px 39px 2px rgba(0,0,0,0.1);
        cursor: pointer;
    }
`);

export default styled(({results, ...props}) => {
    if (results.length === 0) {
        return (
            <Flex flexGrow="1" alignItems="center" flexDirection="column" pt={3} {...props}>
                <Placeholder title="Aucun résultat" icon={faSadTear}/>
            </Flex>
        )
    }

    return (
        <Flex flexGrow="1" alignItems="center" flexDirection="column" pt={3} {...props}>
            {
                results.map(result => (
                    <SearchResult key={result.siren} siren={result.siren} denomination={result.denomination}
                                  count={result.acts_count}/>
                ))
            }
        </Flex>
    )
})`
    background-color: #F6F7F9;
`;
