import React from 'react'
import {Flex} from "reflexbox";

export default class NotFound extends React.Component {
    render() {
        return (
            <Flex flexDirection="column" height="100%">
                <Flex height="30%" justifyContent="center" alignItems="center">
                    <iframe src="https://giphy.com/embed/xULW8vi25RrpxQrmrm" height="100%" frameBorder="0"
                            className="giphy-embed" allowFullScreen/>
                </Flex>
                <Flex flexGrow="1" justifyContent="center" alignItems="center" flexDirection="column">
                    <Flex fontSize="2rem" style={{position: 'relative', zIndex: '2'}}>
                        Oops !
                        <div style={{
                            backgroundColor: '#005CFA',
                            width: '100%',
                            height: '10px',
                            position: 'absolute',
                            zIndex: '-1',
                            bottom: '8px'
                        }}/>
                    </Flex>
                    <Flex fontSize="1.5rem">
                        Il semble que vous soyez perdu !
                    </Flex>
                </Flex>
            </Flex>
        )
    }
}
