import styled from "styled-components";
import {Flex} from "reflexbox";
import React from "react";

export default styled(({message, ...props}) => (
    <Flex flexGrow="1" justifyContent="center" alignItems="center" flexDirection="column" {...props}>
        <Flex style={{fontSize: '8rem'}}>Oops !</Flex>
        {message}
    </Flex>
))`
    color: #ff7877;
    font-size: 1.2rem;
`;
