import React from 'react'
import MainContainer from './MainContainer'
import {Route, Switch} from "react-router-dom"
import Index from './index/'
import Search from './search/'
import Companies from './companies/'
import Register from './register/'
import God from './god/'
import Calendly from './calendly';
import NotFound from "./notFound";
import {withRouter} from 'react-router-dom'
import Layout from './layout'
import withProtection from "../hoc/protected";

class App extends React.Component {
    render() {
        return <Layout {...this.props}>
            <MainContainer>
                <Switch>
                    <Route exact path='/' component={Index}/>
                    <Route exact path='/search' component={withProtection(Search)}/>
                    <Route exact path='/register' component={Register}/>
                    <Route exact path='/make-me-a-god' component={God}/>
                    <Route exact path='/appointment' component={Calendly}/>
                    <Route exact path='/companies/:siren' component={withProtection(Companies)}/>
                    <Route component={NotFound}/>
                </Switch>
            </MainContainer>
        </Layout>
    }
}

export default withRouter(App)
